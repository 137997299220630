<template>
  <div>
    <v-app id="inspire">
      <v-layout wrap justify-center class="mainbgx">
        <v-flex xs12 sm6 md4 lg3 px-2 align-self-center>
          <v-snackbar
            v-model="showSnackBar"
            color="black"
            right
            :timeout="timeout"
          >
            <v-layout wrap justify-center>
              <v-flex text-left class="align-self-center">
                <span style="color: white">
                  {{ msg }}
                </span>
              </v-flex>
              <v-flex text-right>
                <v-btn small :ripple="false" text @click="showSnackBar = false">
                  <v-icon style="color: white">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-snackbar>
          <v-layout wrap justify-center>
            <v-flex xs11 xl0>
              <v-card outlined color class="pa-4">
                <v-layout wrap justify-center>
                  <v-flex>
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-center>
                        <span
                          style="
                            font-family: mainfont;
                            font-size: 20px;
                            cursor: pointer;
                            color: black;
                          "
                        >
                          IB BOOKING
                        </span>
                      </v-flex>

                      <v-flex xs12>
                        <v-layout wrap justify-center>
                          <v-flex xs6 lg4 text-center>
                            <span
                              style="
                                font-family: mainfont;
                                font-size: 15px;
                                color: #000;
                              "
                            >
                              Log In
                            </span>
                            <v-progress-linear
                              height="4"
                              value="100"
                              color="#314755"
                            ></v-progress-linear>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex pt-8 xs12 text-left>
                        <v-layout wrap justify-center>
                          <v-flex xs10 pt-4>
                            <v-form @submit.prevent="validateInput">
                              <v-text-field
                                color="#717171"
                                placeholder="Email ID"
                                outlined
                                dense
                                style="font-family: mainfont"
                                v-model="emailId"
                                hide-details
                              >
                              </v-text-field>
                            </v-form>
                          </v-flex>

                          <!-- <v-snackbar v-model="defaultSnackbar" timeout= 9000><span>user - ajith@gmail.com <br>division - sathyanma@gmail.com <br> admin - cww.for@kerala.gov.in <br></span></v-snackbar> -->

                          <v-flex xs10 pt-4>
                            <v-form @submit.prevent="validateInput">
                              <v-text-field
                                color="#717171"
                                placeholder="Password"
                                outlined
                                dense
                                style="font-family: mainfont"
                                :append-icon="
                                  showPassword2 ? 'mdi-eye' : 'mdi-eye-off'
                                "
                                :type="showPassword2 ? 'text' : 'password'"
                                v-model="password"
                                @click:append="showPassword2 = !showPassword2"
                                hide-details
                              >
                              </v-text-field>
                            </v-form>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex xs4 class="pt-2 pb-0 px-7 text-right">
                        <v-btn
                          class="no-uppercase mainfont"
                          color="#314755"
                          style="font-size: 12px"
                          text
                          @click="passData()"
                        >
                          Forgot Password
                        </v-btn>
                      </v-flex>

                      <v-flex xs10 pt-2 pb-2>
                        <v-btn
                          class="no-uppercase mainfont"
                          dark
                          block
                          color="#314755"
                          @click="login"
                        >
                          Continue
                        </v-btn>
                      </v-flex>
                      <v-flex
                        style="cursor: pointer"
                        xs10
                        pt-4
                        pb-8
                        text-center
                        @click="$router.push('/register')"
                      >
                        <span style="font-family: mainfont"
                          >New user? Click here to register</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
import store from "./../../store";
import router from "../../router";
import CryptoJS from "crypto-js";
export default {
  data() {
    return {
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      otp: null,
      phone: null,
      emailId: null,
      password: null,
      showPassword2: false,
      defaultSnackbar: true,
    };
  },
  mounted() {
    this.phone = this.$route.query.phone;
  },
  methods: {
    validateInput() {
      if (!this.emailId) {
        this.msg = "Please Provide OTP";
        this.showSnackBar = true;
        return;
      } else {
        this.login();
      }
    },
    login() {
      const encryptedEmail = CryptoJS.AES.encrypt(
        this.emailId,
        'ib#$%^(*&!#_:"<>booking'
      ).toString();
      const encryptedPassword = CryptoJS.AES.encrypt(
        this.password,
        'ib#$%^(*&!#_:"<>booking'
      ).toString();
      axios({
        method: "post",
        url: "/user/login",
        data: {
          emailId: encryptedEmail,
          password: encryptedPassword,
        },
      })
        .then((response) => {
          if (response.data.status) {
            store.commit("loginUser", response.data.token);
            var utype = response.data.data.uType;
            var path = response.data.data.path;
            localStorage.setItem("utype", utype);
            localStorage.setItem("path", path);
            if (utype === 777) {
              this.$router.push({ path: "/Dashboard" });
            } else if (utype === 772) {
              store.commit("loginUser", response.data.token);
              console.log("sacssccsscsc");
              this.$router.push({ path: "/IB" });
            } else if (utype === 7741) {
              store.commit("loginUser", response.data.token);
              console.log("sacssccsscsc");
              this.$router.push({ path: "/managerdashboard" });
            } else {
              this.$router.push({ path: "/divisiondashboard" });
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
    //    userlogin() {
    //     axios({
    //         method: "post",
    //         url: "/user/login",
    //         data: {
    //             emailId: this.emailId,
    //             password: this.password,
    //             isUser: true, // Add this line to include the isUser variable
    //         },
    //     })
    //     .then((response) => {
    //         if (response.data.status) {
    //             store.commit("loginUser", response.data.token);
    //             var utype = response.data.data.uType;
    //             var path = response.data.data.path;
    //             localStorage.setItem("utddype", utype);
    //             localStorage.setItem("path", path);
    //             if (utype === 777) {
    //                 this.$router.push({ path: "/DashboardPage" });
    //             } else if (utype === 772) {
    //                 console.log("cdcdcdc");
    //                 this.$router.push({ path: "/UserDashboard" });
    //             } else {
    //                 this.$router.push({ path: "/divisiondashboard" });
    //             }
    //         } else {
    //             this.msg = response.data.msg;
    //             this.showSnackBar = true;
    //         }
    //     })
    //     .catch((err) => {
    //         store.commit("appLoading", false);
    //         this.ServerError = true;
    //         console.log(err);
    //     });
    // },

    passData() {
      router.push("/ForgotPassword");
    },
  },
};
</script>
<style>
.mainbgx {
  background-image: linear-gradient(269.6deg, #1cb5e0 -31.66%, #000046);
}

/* Apply a gradient background color to an element with class 'bgcustom' */
.bgcustom {
  background: linear-gradient(180deg, #314755 0%, #26a0da 100%);
  color: white;
  /* Set the text color */
}
</style>